<template>
  <v-container
    v-if="loaded"
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-row>
          <v-col
            v-if="!isNew && !event"
            cols="12"
          >
            <i class="fas fa-spin fa-spinner"></i>
          </v-col>
          <v-col
            v-else
            cols="12"
          >
            <material-card
              color="primary"
              :heading="pageTitle"
            >
              <v-card-text>
                <i
                  v-if="!isNew && !event"
                  class="fas fa-spin fa-spinner"
                ></i>
                <v-row>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.label').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.label"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.label')"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.category').toLowerCase()"
                    >
                      <EventCategoryPicker
                        v-model="form.event_category_id"
                        :errors="errors"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.published_at').toLowerCase()"
                    >
                      <DateTimePicker
                        v-model="form.published_at"
                        :label="$t('forms.published_at')"
                        with-time
                        :errors="errors"
                      />
                    </validation-provider>
                    <div class="d-flex justify-space-between align-center mt-5">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="$t('forms.date_type').toLowerCase()"
                      >
                        <v-select
                          v-model="form.date_type"
                          :items="dateTypeChoices"
                          item-text="label"
                          item-value="key"
                          outlined
                          :error-messages="errors"
                          :label="$t('forms.date_type')"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        rules=""
                        :name="$t('forms.on_homepage').toLowerCase()"
                      >
                        <v-switch
                          v-model="form.is_highlighted"
                          :label="$t('forms.on_homepage')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        rules=""
                        :name="$t('forms.enabled').toLowerCase()"
                      >
                        <v-switch
                          v-model="form.is_enabled"
                          :label="form.is_enabled ? $t('forms.enabled') : $t('forms.disabled')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </div>
                    <div class="d-flex justify-space-between align-center">
                      <validation-provider
                        v-slot="{ errors }"
                        rules=""
                        :name="form.date_type === 'range' ? $t('forms.from').toLowerCase() : 'Date 1'"
                      >
                        <DateTimePicker
                          v-model="form.start_at"
                          :label="form.date_type === 'range' ? $t('forms.from') : 'Date 1'"
                          :errors="errors"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        rules=""
                        :name="form.date_type === 'range' ? $t('forms.until').toLowerCase() : 'Date 2'"
                      >
                        <DateTimePicker
                          v-model="form.end_at"
                          :label="form.date_type === 'range' ? $t('forms.until') : 'Date 2'"
                          :errors="errors"
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.poster').toLowerCase()"
                    >
                      <DropzoneComponent
                        v-model="form.pictures"
                        class="poster-dropzone"
                        :label="$t('dropzone.label_singular')"
                        :max-files="1"
                        :errors="errors"
                        :field-label="$t('forms.poster')"
                        :ratio="7 / 10"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <h3>{{ $t('forms.description') }}</h3>
                    <WYSISYWEditor v-model="form.description" />
                  </v-col>
                </v-row>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/events/events"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-card-text>
            </material-card>
            <material-card
              color="primary"
              class="mt-8"
              :heading="$t('forms.informations')"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.location').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.location"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.location')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.phone').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.phone"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.phone')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.hours').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.hours"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.hours')"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.informations').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.informations"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.informations')"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/events/events"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-card-text>
            </material-card>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteDate"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DropzoneComponent from '../../components/Dropzone';
  import serverError from '../../mixins/serverError';
  import EventCategoryPicker from '../../components/EventCategoryPicker';
  import DateTimePicker from '../../components/DateTimePicker';
  export default {
    name: 'EventForm',

    components: { EventCategoryPicker, DropzoneComponent, DateTimePicker },

    mixins: [serverError],

    data: () => ({
      loaded: false,
      form: {
        label: null,
        description: null,
        event_category_id: null,
        location: null,
        phone: null,
        hours: null,
        informations: null,
        is_highlighted: false,
        is_enabled: false,
        published_at: null,
        pictures: [],
        dates: [],
        date_type: 'range',
        start_at: null,
        end_at: null,
      },
      showDeletionDialog: false,
      dateToDelete: null,
      dateTypeChoices: [
        { key: 'unique', label: 'Date(s) unique(s)' },
        { key: 'range', label: 'Période' },
      ],
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        event: 'event/getEvent',
        categories: 'event/getCategories',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewEvent') : this.$t('pageTitles.Editing') + this.event.label;
      },
    },

    created() {
      if (!this.isNew) {
        this.$store.dispatch('event/fetchEvent', this.$route.params.id)
          .then(() => {
            this.form = {
              ...this.event,
              start_at: this.event.start_at ? this.event.start_at.slice(0, -9) : null,
              end_at: this.event.end_at ? this.event.end_at.slice(0, -9) : null,
              pictures: this.event.picture ? [this.event.picture] : [],
            };
            this.loaded = true;
          });
      } else {
        this.loaded = true;
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, label, description, event_category_id, location, phone, hours, informations, is_highlighted, pictures, is_enabled, date_type, start_at, end_at, published_at } = this.form
          const picture_id = pictures && pictures[0] && pictures[0].id ? pictures[0].id : null;
          const payload = { id, label, event_category_id, description, location, phone, hours, informations, is_highlighted, picture_id, published_at, is_enabled, date_type, start_at, end_at };
          const action = this.isNew ? 'event/createEvent' : 'event/updateEvent';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/events/events');
            })
            .catch(error => this.displayErrors(error));
        }
      },
      dateAddedHandler(date) {
        this.form.dates.push(date);
      },
      initiateDateDelete(date) {
        this.dateToDelete = date.id;
        this.showDeletionDialog = true;
      },
      closeDeletionDialog() {
        this.dateToDelete = null;
        this.showDeletionDialog = false;
      },
      deleteDate() {
        this.$store.dispatch('event/deleteDate', this.dateToDelete)
          .then(() => {
            this.form.dates = this.form.dates.filter(date => date.id !== this.dateToDelete);
            this.$toasted.success(this.$t('item_deleted'));
          })
          .catch(error => this.displayErrors(error))
          .finally(() => this.closeDeletionDialog());
      },
    },
  }
</script>

<style lang="scss">
.poster-dropzone {
  .picture-preview-card {
    width: 100%;
  }
}
</style>
