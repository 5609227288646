var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('v-container',{attrs:{"id":"regular-forms-view","fluid":"","tag":"section"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[(!_vm.isNew && !_vm.event)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"primary","heading":_vm.pageTitle}},[_c('v-card-text',[(!_vm.isNew && !_vm.event)?_c('i',{staticClass:"fas fa-spin fa-spinner"}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.label').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.label')},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.category').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('EventCategoryPicker',{attrs:{"errors":errors},model:{value:(_vm.form.event_category_id),callback:function ($$v) {_vm.$set(_vm.form, "event_category_id", $$v)},expression:"form.event_category_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.published_at').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTimePicker',{attrs:{"label":_vm.$t('forms.published_at'),"with-time":"","errors":errors},model:{value:(_vm.form.published_at),callback:function ($$v) {_vm.$set(_vm.form, "published_at", $$v)},expression:"form.published_at"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between align-center mt-5"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.date_type').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dateTypeChoices,"item-text":"label","item-value":"key","outlined":"","error-messages":errors,"label":_vm.$t('forms.date_type')},model:{value:(_vm.form.date_type),callback:function ($$v) {_vm.$set(_vm.form, "date_type", $$v)},expression:"form.date_type"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.on_homepage').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.$t('forms.on_homepage'),"error-messages":errors},model:{value:(_vm.form.is_highlighted),callback:function ($$v) {_vm.$set(_vm.form, "is_highlighted", $$v)},expression:"form.is_highlighted"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.enabled').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.form.is_enabled ? _vm.$t('forms.enabled') : _vm.$t('forms.disabled'),"error-messages":errors},model:{value:(_vm.form.is_enabled),callback:function ($$v) {_vm.$set(_vm.form, "is_enabled", $$v)},expression:"form.is_enabled"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('validation-provider',{attrs:{"rules":"","name":_vm.form.date_type === 'range' ? _vm.$t('forms.from').toLowerCase() : 'Date 1'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTimePicker',{attrs:{"label":_vm.form.date_type === 'range' ? _vm.$t('forms.from') : 'Date 1',"errors":errors},model:{value:(_vm.form.start_at),callback:function ($$v) {_vm.$set(_vm.form, "start_at", $$v)},expression:"form.start_at"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.form.date_type === 'range' ? _vm.$t('forms.until').toLowerCase() : 'Date 2'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTimePicker',{attrs:{"label":_vm.form.date_type === 'range' ? _vm.$t('forms.until') : 'Date 2',"errors":errors},model:{value:(_vm.form.end_at),callback:function ($$v) {_vm.$set(_vm.form, "end_at", $$v)},expression:"form.end_at"}})]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.poster').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropzoneComponent',{staticClass:"poster-dropzone",attrs:{"label":_vm.$t('dropzone.label_singular'),"max-files":1,"errors":errors,"field-label":_vm.$t('forms.poster'),"ratio":7 / 10},model:{value:(_vm.form.pictures),callback:function ($$v) {_vm.$set(_vm.form, "pictures", $$v)},expression:"form.pictures"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('forms.description')))]),_c('WYSISYWEditor',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/events/events"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)],1),_c('material-card',{staticClass:"mt-8",attrs:{"color":"primary","heading":_vm.$t('forms.informations')}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.location').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.location')},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.phone').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.phone')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.hours').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.hours')},model:{value:(_vm.form.hours),callback:function ($$v) {_vm.$set(_vm.form, "hours", $$v)},expression:"form.hours"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.informations').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.informations')},model:{value:(_vm.form.informations),callback:function ($$v) {_vm.$set(_vm.form, "informations", $$v)},expression:"form.informations"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/events/events"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)],1)],1)],1)],1)]}}],null,false,2706127300)}),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.showDeletionDialog),callback:function ($$v) {_vm.showDeletionDialog=$$v},expression:"showDeletionDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" "+_vm._s(_vm.$t('delete_confirm'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.closeDeletionDialog}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteDate}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }